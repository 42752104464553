.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

button {
  appearance: button;
  -webkit-appearance: button;
  -moz-appearance: button;
  border: 0;
  background-color: transparent;
  outline: none;
}

.blind {
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.noscroll {
    overflow: hidden;
}

#dim {
    display: none;
    opacity: 0.5;
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #000;
}
#dim.show {
    display: block;
}

.divider {
    position: relative;
    display: block;
    width: 100%;
    height: 10px;
    background-color: #F5F5F5;
}

.divider::after {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ededed;
}

/* Customize the label (the container) */
.custom-chk {
    position: relative;
    display: inline-block;
    padding-left: 24px;
    line-height: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: .875rem;
}

/* Hide the browser's default checkbox */
.custom-chk input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.custom-chk .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    // border: 1px solid #999;
    // border-radius: 5px;
    // background-color: #fff;
    background-image: url('./assets/images/checkbox_n.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
}

/* On mouse-over, add a grey background color */
// .custom-chk:hover input ~ .checkmark {
    // background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.custom-chk input:checked ~ .checkmark {
    // border: none;
    // background-color: #999;
    background-image: url('./assets/images/checkbox_s.png');
}

/* Create the checkmark/indicator (hidden when not checked) */
// .custom-chk:after {
//     position: absolute;
//     display: none;
// }

/* Show the checkmark when checked */
// .custom-chk input:checked ~ .checkmark:after {
//     display: block;
// }

/* Style the checkmark/indicator */
// .custom-chk .checkmark:after {
//     content: '';
//     position: relative;
//     display: none;
//     left: 6px;
//     top: 5px;
//     width: 6px;
//     height: 6px;
//     border: solid #fff;
//     border-width: 0 1.8px 1.8px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
// }

/* Customize the label (the container) */
.custom-rdo {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    line-height: 22px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: .875rem;
}

/* Hide the browser's default checkbox */
.custom-rdo input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.custom-rdo .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
    // border: 1px solid #999;
    // border-radius: 5px;
    // background-color: #fff;
    background-image: url('./assets/images/radio_n.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
}

/* On mouse-over, add a grey background color */
// .custom-rdo:hover input ~ .checkmark {
    // background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.custom-rdo input:checked ~ .checkmark {
    // border: none;
    // background-color: #999;
    background-image: url('./assets/images/radio_s.png');
}

/* Create the checkmark/indicator (hidden when not checked) */
// .custom-rdo:after {
//     position: absolute;
//     display: none;
// }

/* Show the checkmark when checked */
// .custom-rdo input:checked ~ .checkmark:after {
//     display: block;
// }

/* Style the checkmark/indicator */
// .custom-rdo .checkmark:after {
//     content: '';
//     position: relative;
//     display: none;
//     left: 6px;
//     top: 5px;
//     width: 6px;
//     height: 6px;
//     border: solid #fff;
//     border-width: 0 1.8px 1.8px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
// }

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');

html {
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: keep-all;
}

body
,button
,input
,ul
,li {
    margin: 0;
    border: 0;
    padding: 0;
}

li::marker {
    content: "";
}

code {
    font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        'Courier New',
        monospace;
}

.tabcontent {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}
